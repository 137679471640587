.contact {
  //border: 1px solid var(--font-third);
  border-radius: 50px;
  padding: 30px 55px 30px 35px;
  position: fixed;
  left: 40px;
  top: var(--top-padding);
  height: 85%;
  width: 406px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  @media screen and (min-width: 1200px) {
    background-color: var(--color-primary-lighter);
  }
}

.contactDesktop {
  position: relative;
  border: none;
  padding: var(--spacing-m) 0;
  width: 100%;
  height: 100vh;
  left: 0;
  top: var(--spacing-m);
  margin-top: var(--spacing-m);
}

.sectionTitle {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid var(--font-secondary);
  width: fit-content;
  border-radius: 50px;
  color: var(--font-secondary);
  text-decoration: none;
  cursor: pointer;
}

.header {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.subheader {
  font-size: 1.6rem;
  color: var(--font-secondary);
}

.img {
  width: 100%;
  align-self: center;
  max-width: 350px;
  border-radius: 25px;
  background: var(--color-description);
  display: none;
  transition: background-color 0.2s ease-in-out;
  @media screen and (min-height: 900px) and (min-width: 1200px) {
    display: block;
  }
}

.footer {
  color: var(--font-third);
  font-size: 1.4rem;
  align-self: center;
}

.infoContainer {
  align-self: stretch;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  min-height: 250px;
  justify-content: space-evenly;
  flex: 1;
  @media screen and (min-width: 1200px) {
    min-height: auto;
  }
}

.infoSection {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}

.locationSection {
  padding-left: 25px;
  min-width: 280px;
  gap: 20px;
  font-size: 1.7rem;
  line-height: 1.5;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.textSecondary {
  color: var(--font-secondary);
}

.locationIcon {
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  transform: rotate(270deg);
  color: var(--font-secondary);
}

.socialIcon {
  color: var(--font-third);
  font-size: 4.8rem;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--font-third);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: var(--font-secondary);
    color: var(--font-secondary);
    transition: all 0.1s ease-in-out;
  }
}

.ctaSection {
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  min-width: 300px;
}

.buttonHire {
  text-decoration: none;
  border: 2px solid var(--font-secondary);
  width: 100%;
  padding: 15px 0;
  border-radius: 50px;
  font-size: 16px;
  text-transform: uppercase;
  background: var(--font-secondary);
  color: var(--font-tooltip);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--font-secondary);
    background: transparent;
    transition: all 0.1s ease-in-out;
  }
}

.buttonResume {
  border: 2px solid var(--font-secondary);
  width: 100%;
  border-radius: 50px;
  font-size: 18px;
  padding: 5px;
  text-transform: uppercase;
  background: var(--font-secondary);
  transition: all 0.1s ease-in-out;
  color: var(--font-tooltip);
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: var(--font-secondary);
    background: transparent;
    transition: all 0.1s ease-in-out;
  }
}

.buttonDownload {
  flex: 1;
  border: 2px solid var(--font-secondary);
  padding: 10px 0;
  border-radius: 20px;
  font-size: 14px;
  text-transform: uppercase;
  background: var(--font-secondary);
  transition: all 0.1s ease-in-out;
  color: var(--font-tooltip);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: var(--font-secondary);
    background: transparent;
    transition: all 0.1s ease-in-out;
  }
}
