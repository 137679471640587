.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  scroll-behavior: auto;
  margin-left: 20px;
}

.containerDesktop {
  flex-direction: row;
}

.menu {
  display: flex;
  overflow: scroll;
  position: relative;
}

.menuDesktop {
  flex-direction: column;
  display: flex;
  max-width: 150px;
  padding-left: 10px;
  border-left: 1px solid var(--font-third);
  position: relative;
  height: fit-content;
  align-items: flex-start;
  overflow: unset;
}

.menuButton {
  font-size: 1.4rem;
  background: none;
  border: none;
  padding: 15px 5px;
  text-align: center;
  color: var(--font-primary);
  cursor: pointer;
  min-width: 140px;

  &Active {
    color: var(--font-secondary);
  }
}

.menuButtonDesktop {
  text-align: left;
}

.description {
  font-size: 1.5rem;
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100px;
  background: var(--font-secondary);
}

.indicatorDesktop {
  top: 0;
  left: -2px;
  height: 47px;
  width: 3px;
}

.techstackColumn {
  margin-top: 15px;
  list-style: none;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.duty {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.dutyPoint {
  font-size: 2rem;
  color: var(--font-secondary);
  transform: rotate(90deg);
  margin-right: 10px;
}

.bootcamp {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header {
  font-size: 1.8rem;
  line-height: 1.4;
}

.subHeader {
  padding-left: 5px;
  color: var(--font-third);
  font-size: 1.5rem;
  line-height: 1.4;
}

.link {
  text-decoration: none;
  color: var(--font-secondary);
  cursor: pointer;
}
