.buttonTheme {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--font-third);
  background: transparent;
  cursor: pointer;

  padding: 2px;
  font-size: 1.9rem;
  width: 30px;
  height: 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
  color: var(--font-third);

  &:hover {
    color: var(--font-secondary);
    border-color: var(--font-secondary);
    transition: all 0.2s ease-in-out;
  }

  &:hover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 5px);
    width: calc(100% + 5px);
    border: 1px solid var(--font-secondary);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }
}
