.links {
  list-style: none;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    display: block;
    height: 100%;
    min-width: 250px;
    padding: 15px 40px;
    color: var(--font-third);
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: 300;
    width: 100%;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--font-secondary);
      transition: color 0.1s ease-in-out;
    }
  }
}

.linksDesktop {
  padding: 0 80px;
  gap: 50px;
  margin-bottom: 100px;

  a {
    font-size: 4rem;
    padding: 20px 15px;
  }
}

.linkContainer {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.linkIcon {
  position: absolute;
  padding: 10px 0 10px 10px;
  top: 0;
  left: 0;
  color: var(--font-secondary);
  font-size: 2rem;
}

.mobileIcon {
  margin-right: 15px;
}

.link {
  &Active {
    a {
      color: var(--font-secondary);
    }
  }

  &Dimmed {
    a {
      color: var(--font-dimmed);
      transition: color 0.2s ease-in-out;
    }
  }
}
