.container {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  max-width: 700px;
  margin: 0 auto;
}

.stackSection {
  flex: 1;
  min-width: 250px;

  & .stackHeaderDimmed {
    color: var(--font-third);
    transition: color 0.2s ease-in-out;
  }

  & .stackListDimmed {
    color: var(--font-third);
    transition: color 0.2s ease-in-out;
  }
}

.stackHeader {
  text-align: center;
  font-size: 2.6rem;
  margin-bottom: var(--spacing-m);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--font-secondary);
  gap: 15px;
  transition: color 0.2s ease-in-out;
}

.stackList {
  color: var(--font-primary);
  list-style: none;
  display: grid;
  gap: 0;
  transition: color 0.2s ease-in-out;
}

.stackListItem {
  padding: 10px 0;
  text-align: center;
}

.itemDimmed {
  color: var(--font-third);
  transition: color 0.2s ease-in-out;
}

.itemActive {
  color: red;
}

.icon {
  font-size: 4rem;
}
