.section {
  padding-top: var(--spacing-m);
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 500px) {
    padding-top: var(--spacing-l);
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  border: 1px solid var(--font-secondary);
  width: fit-content;
  border-radius: 50px;
  margin-bottom: var(--spacing-l);
  color: var(--font-secondary);
  text-decoration: none;
  cursor: pointer;
}

.icon {
  color: var(--font-secondary);
}

.sectionHome {
  min-height: 100vh;
}

.sectionAbout,
.sectionSkills {
  margin-bottom: var(--spacing-xl);
}
