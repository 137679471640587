#stars {
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.AppBackgroundCanvas {
  display: block;
  position: fixed;
  background: var(--color-primary);
  width: 100%;
  min-height: 100%;
  z-index: -1;
}
