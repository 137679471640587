@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

:root {
  display: block;
  box-sizing: border-box;
  --swiper-navigation-color: white !important;
  --swiper-theme-color: white !important;
  --font-family-primary: "Inter", sans-serif;

  --font-size-base: 1.6rem;

  --space-between-sections: 4rem;

  --color: 0, 0%; /*the base color*/
  --l: 100%; /*the initial lightness*/
  --color-primary: hsl(var(--color), var(--l));
  --color-primary-lighter: hsl(var(--color), calc(var(--l) - 10%));
  --color-primary-darker: hsl(var(--color), calc(var(--l) - 10%));
  --color-secondary: #1dc275;
  --color-description: #0b9052;
  --font-primary: #404040;
  --font-secondary: #1dc275;
  --font-third: #727272;
  --menu-toggle: #7d7d7d;
  --font-tooltip: #4b4b4b;
  --font-dimmed: #c9c9c9;

  /* ---spacing---*/
  --top-padding: 50px;
  --spacing-xxl: 100px;
  --spacing-xl: 80px;
  --spacing-l: 50px;
  --spacing-m: 30px;
  --spacing-s: 20px;
  --spacing-xs: 10px;

  /*    ---responsiveness---*/

  --mobile-l: 500px;
  --tablet-portrait: 768px;
  --tablet: 1024px;
  --desktop-s: 1200px;
  --desktop: 1366px;
}

.darkmode {
  --color: 0, 0%; /*the base color*/
  --l: 12%; /*the initial lightness*/
  --color-primary: hsl(var(--color), var(--l));
  --color-primary-lighter: hsl(var(--color), calc(var(--l) + 7%));
  --color-primary-darker: hsl(var(--color), calc(var(--l) - 1%));
  --font-primary: #eaeaea;
  --font-dimmed: #383838;
  --swiper-navigation-color: #1dc275 !important;
  --swiper-theme-color: #1dc275 !important;
  --menu-toggle: #ffffff;
}

.yellow {
  --font-secondary: #eab515;
  --color-description: #9b7e00;
  --font-description: #ffffff;
  --swiper-navigation-color: #9b7e00 !important;
  --swiper-theme-color: #9b7e00 !important;
}

.orange {
  --font-secondary: #ff7338;
  --font-tooltip: #1d1d1d;
  --color-description: #c43e07;
  --font-description: #ffffff;
  --swiper-navigation-color: #c43e07 !important;
  --swiper-theme-color: #c43e07 !important;
}

.red {
  --font-secondary: #f31313;
  --font-tooltip: #dbdbdb;
  --color-description: #aa0707;
  --font-description: #ffffff;
  --swiper-navigation-color: #aa0707 !important;
  --swiper-theme-color: #aa0707 !important;
}

.blue {
  --font-secondary: #13c5fd;
  --color-description: #0082aa;
  --font-description: #ffffff;
  --swiper-navigation-color: #0082aa !important;
  --swiper-theme-color: #0082aa !important;
}

.green {
  --font-secondary: #1dc275;
  --color-description: #0b9052;
  --font-description: #ffffff;
  --swiper-navigation-color: #0b9052 !important;
  --swiper-theme-color: #0b9052 !important;
}

.pink {
  --font-secondary: #ff99cc;
  --font-tooltip: #3f3f3f;
  --color-description: #ab0d63;
  --font-description: #fff;
  --swiper-navigation-color: #ab0d63 !important;
  --swiper-theme-color: #ab0d63 !important;
}

.purple {
  --font-secondary: #493ee5;
  --font-tooltip: #dbdbdb;
  --color-description: #493ee5;
  --font-description: #dbdbdb;
  --swiper-navigation-color: #493ee5 !important;
  --swiper-theme-color: #493ee5 !important;
}

* {
  font-family: var(--font-family-primary);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  background: #1f1f1f;
}

html::-webkit-scrollbar {
  display: none;
  visibility: hidden;
}

body {
  color: var(--font-primary);
  transition: all 0.1s ease-in-out;
  font-size: var(--font-size-base);
}

.swiper-button-next {
  color: var(--color-description) !important;
}

.swiper-button-prev {
  color: var(--color-description) !important;
}
