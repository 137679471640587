.work {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.swiper {
  background-color: var(--color-description);
  transition: background-color 0.2s ease-in-out;
}

.swiperContainer {
  flex: 2;
  width: 100%;
  max-width: 600px;
  transition: background-color 0.2s ease-in-out;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.swiperHint {
  padding: 15px 10px 0;
  color: var(--font-third);
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 07px;
  transition: color 0.2s ease-in-out;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: -20px;
  padding: 30px 20px;
  background-color: var(--color-primary-darker);

  &:hover {
    & .swiper {
      background-color: transparent;
      transition: background-color 0.2s ease-in-out;
    }

    & .image {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    & .title {
      color: var(--font-secondary);
      transition: color 0.2s ease-in-out;
    }

    & .projectType {
      color: var(--font-primary);
      transition: color 0.2s ease-in-out;
    }

    & .description {
      color: var(--font-primary);
      transition: color 0.2s ease-in-out;
    }

    .swiperHint {
      color: var(--font-primary);
      transition: color 0.2s ease-in-out;
    }
  }

  @media screen and (min-width: 500px) {
    margin: 0;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.cardReversed {
  & .swiperHint {
    justify-content: flex-end;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }
}

.descriptionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  min-width: 250px;
}

.descriptionContainerAlternative {
  @media screen and (min-width: 768px) {
    & .title {
      text-align: right;
    }

    & .description {
      text-align: right;
      padding: 0 0 0 10px;
    }

    & .techStack {
      justify-content: flex-end;
    }
    & .links {
      justify-content: flex-end;
    }
  }
}

.image {
  user-select: none;
  display: block;
  width: 100%;
  height: auto;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

.title {
  font-size: 2rem;
  transition: color 0.2s ease-in-out;
  padding: 10px;
}

.projectType {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: var(--font-third);
  transition: color 0.2s ease-in-out;
}

.description,
.techStack {
  color: var(--font-third);
  line-height: 1.4;
  transition: color 0.2s ease-in-out;
}

.description {
  padding: 0 10px 0 0;
}

.techStack {
  padding: 10px;
  font-size: 1.3rem;
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.links {
  display: flex;
  gap: 25px;
  font-size: 2.4rem;
  padding: 10px;
}

.foldersHeader {
  font-size: 2.3rem;
  text-decoration: none;
  color: var(--font-secondary);
  margin-bottom: var(--spacing-l);
  display: block;
}

.folderContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: fit-content;
  margin: 0 auto;
  justify-content: center;
}

.projectFolder {
  max-width: 300px;
  background: var(--color-primary-darker);
  padding: 25px 25px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  transition: color 0.2s ease-in-out;

  &:hover {
    & .folderName {
      color: var(--font-secondary);
      transition: color 0.2s ease-in-out;
    }

    .folderDescription {
      color: var(--font-primary);
      transition: color 0.2s ease-in-out;
    }
  }
}

.folderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  color: var(--font-third);
}

.folderIcon {
  font-size: 4rem;
  color: var(--font-third);
}

.stackList {
  list-style: none;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 1.3rem;
  color: var(--font-third);
}

.folderName {
  font-size: 2rem;
  transition: color 0.2s ease-in-out;
}

.folderDescription {
  font-size: 1.5rem;
  line-height: 1.4;
  color: var(--font-third);
  transition: color 0.2s ease-in-out;
}

.projectWrapper {
  padding-top: var(--top-padding);
  min-height: 100vh;
}

.projectLink {
  text-decoration: none;
  color: var(--font-secondary);
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--font-primary);
    transition: all 0.2s ease-in-out;
  }
}
