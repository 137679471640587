.container {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
}

.swiper {
  width: 100%;
  max-width: 320px;
  @media screen and (min-width: 768px) {
    max-width: 400px;
  }
}

.header {
  font-size: 2.3rem;
  text-decoration: none;
  color: var(--font-secondary);
  display: block;
}

.techstackContainer {
  padding-left: 50px;
}

.aboutSection {
  padding-top: var(--top-padding);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.introductionDescription {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1.5rem;
  line-height: 1.3;
  color: var(--font-primary);
  flex: 2;
  min-width: 300px;
}

.duties,
.techstackColumn {
  list-style: none;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.duties {
  margin-left: 10px;
  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
}

.duty {
  display: flex;
  align-items: center;
}

.dutyPoint {
  font-size: 2rem;
  color: var(--font-secondary);
  transform: rotate(90deg);
  margin-right: 10px;
  min-width: 20px;
}

.dutyDescription {
  max-width: 600px;
}

.techstackListsContainer {
  margin-top: 20px;
  display: flex;
  gap: 50px;
  font-size: 1.4rem;
  padding-left: 20px;
}

.experienceItem {
  display: flex;
  flex-direction: column;
  gap: 25px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 768px) {
    padding-left: 20px;
  }
}

.itemLink {
  text-decoration: none;
  cursor: pointer;
  color: var(--font-secondary);
}

.itemTitle {
  font-size: 2rem;
  line-height: 1.4;
}

.itemSubtitle {
  padding-left: 5px;
  font-size: 1.5rem;
  color: var(--font-third);
}

.bootcampsContainer {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
