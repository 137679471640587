.buttonLang {
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: all 0.2s ease-in-out;
  position: relative;
  background: transparent;

  &:after {
    content: "";
    border: 1px solid var(--font-third);
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 16px);
    left: calc(50% - 14px);
    height: 30px;
    width: 30px;
    z-index: 3;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: var(--font-secondary);
    border-color: var(--font-secondary);
    transition: all 0.2s ease-in-out;

    &:after {
      border: 1px solid var(--font-secondary);
      transition: all 0.2s ease-in-out;
    }
  }

  &:after {
    background-image: url("/assets/flag-pl.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.buttonLangPl {
  &:after {
    background-image: url("/assets/flag-pl.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.buttonLangEn {
  &:after {
    background-image: url("/assets/flag-en.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.buttonLangDesktop {
  width: 30px;
  height: 30px;
  position: fixed;
  left: 427px;
  top: calc(50% + 25%);
  transform: translateY(-50%);
  z-index: 4;

  &:before {
    content: "";
    //border: 1px solid var(--font-third);
    position: absolute;
    top: calc(50% - 28px);
    left: -13px;
    height: 56px;
    width: 30px;
    background: var(--color-primary);
    z-index: -1;
    border-top-left-radius: 150px;
    border-bottom-left-radius: 150px;
    border-right: none;
  }
}
