.nav {
  padding: 25px 15px;
  position: fixed;
  left: 420px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &:before {
    content: "";
    position: absolute;
    border-radius: 25px 0 0 25px;
    top: 0;
    left: 0;
    height: 100%;
    width: 52%;
    background: var(--color-primary);
  }
}

.links {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  a {
    color: var(--font-third);
    font-size: 2rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0 auto;

    &:hover {
      color: var(--font-secondary);
      transition: all 0.1s ease-in-out;
    }
  }
}

.link {
  color: var(--font-third);
  transition: all 0.1s ease-in-out;
  position: relative;

  &:hover {
    color: var(--font-secondary);
    transition: all 0.1s ease-in-out;
  }

  &Active {
    a {
      color: var(--font-secondary);
    }
  }
}
