.container {
  width: fit-content;
  margin: auto;
  justify-self: center;
  padding-bottom: 100px;
  @media screen and (min-height: 800px) {
    padding-bottom: 200px;
  }
}

.intro {
  color: var(--font-secondary);
  margin-bottom: 20px;
  font-size: 2rem;
}

.title,
.titleSecondary,
.subtitle {
  font-weight: 400;
  line-height: 1.4;
}

.title,
.titleSecondary {
  font-size: 4.4rem;
  @media screen and (min-width: 500px) {
    font-size: 6.2rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 7.8rem;
  }
  @media screen and (min-width: 1366px) {
    font-size: 8.8rem;
  }
  @media screen and (min-width: 1600px) {
    font-size: 10rem;
  }
}

.titleSecondary {
  color: var(--font-secondary);
}

.subtitle {
  font-size: 2.6rem;
  margin-bottom: 30px;
  @media screen and (min-width: 500px) {
    font-size: 3rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 4.8rem;
  }
}

.description {
  font-size: 1.5rem;
  color: var(--font-third);
  display: flex;
  flex-direction: column;
  gap: 15px;
  letter-spacing: 0.5px;
  max-width: 700px;
  line-height: 1.5;
  @media screen and (min-width: 500px) {
    font-size: 1.6rem;
  }
}

.link {
  text-decoration: none;
  color: var(--font-secondary);
  cursor: pointer;
}
