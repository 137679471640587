.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0 0 30px;
}

.container > * {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}

.titleContainer {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-secondary);
}

.title {
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.titleDesktop {
  font-size: 2.2rem;
}

.linkContainer {
  flex: 3;
  display: flex;
  gap: 10px;
}

.linkLine {
  flex: 1;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 9px;
    top: 0;
    height: 100%;
    width: 1px;
    background: var(--font-third);
  }

  &:after {
    content: "";
    position: absolute;
    left: 9px;
    bottom: 0;
    height: 0;
    width: 1px;
    background: var(--font-secondary);
    transition: height 0.4s ease-in-out;
  }
}

.linkContainer:hover .linkLine:after {
  height: 100%;
}

.headerLink {
  letter-spacing: 1px;
  color: var(--font-third);
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none;
  font-weight: 300;
  margin: 0 auto;
  transition-delay: 0.4s;
  overflow: hidden;

  &:hover {
    color: var(--font-secondary);
    transition: color 0.1s ease-in-out;
  }
}

.headerLinkDesktop {
  font-size: 2rem;
}
