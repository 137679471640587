.colorSelect {
  display: flex;
  gap: 15px;
  flex-direction: column-reverse;
}

.colorSelectDesktop {
  flex-direction: row;
}

.colorButton {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &Active:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 5px);
    width: calc(100% + 5px);
    border: 1px solid var(--font-third);
    border-radius: 50%;
  }

  &Pallet:hover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 5px);
    width: calc(100% + 5px);
    border: 1px solid var(--font-secondary);
    border-radius: 50%;
  }
}

.yellow {
  background: #e4af12;
}

.orange {
  background: #f57722;
}

.red {
  background: #f31313;
}

.blue {
  background: #13c5fd;
}

.purple {
  background: #493ee5;
}

.green {
  background: #19b068;
}

.pink {
  background: #ff99cc;
}
